import { component, observable, observableArray } from 'knockout-decorators'

import { getPageData, PageDataType } from '../../utils/ajax'

import '../item-event/item-event'

import { EventItem } from '@tixa/schema'
import { ItemList } from '@tixa/schema'

import source from './list-event.html?raw'

interface Params {
  feed: PageDataType
  display?: string
}

@component('list-event', source)
export class EventList {
  @observableArray public events: EventItem[] = []

  @observable public name = ''
  @observable public url = ''
  @observable public hasUrl = false

  @observable public display = ''
  @observable public isGrid = false

  private feed: PageDataType
  private isInit = false

  constructor(params: Params) {
    this.feed = params.feed
    this.display = params.display ? params.display : ''
    this.isGrid = this.display.indexOf('grid') !== -1
  }

  public init() {
    if (!this.isInit) {
      const value = getPageData<ItemList<EventItem>>(this.feed)
      value.itemListElement.forEach((item) => this.events.push(item.item))

      this.name = value.name
      this.hasUrl = null != value.url
      this.url = null != value.url ? value.url : ''

      this.isInit = true
    }
  }
}
