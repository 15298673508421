import Fecha from 'fecha'
import { component } from 'knockout-decorators'

import source from './item-event.html?raw'
import './item-event.less'

@component('item-event', source)
export class EventGUI {
  private data: Event

  constructor(params: Event) {
    this.data = params
  }

  public getEventMonth(date: string): string {
    return Fecha.format(new Date(date), 'MMMM')
  }

  public getEventDay(date: string): string {
    return Fecha.format(new Date(date), 'DD')
  }
}
